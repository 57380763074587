import React, { Component } from "react";
import "./App.css";
import ComponentManager from "./ComponentManager";
import DomainManager from "./DomainManager";
import ApiManager from "./ApiManager";
import { COLOR_BLUE } from "./Constants";
import WebsiteApiManager from "./WebsiteApiManager";
import WebsiteManager from "./WebsiteManager";
import ApiConstants from "./ApiConstants";
import DKSpaceH from "./eCommerce/Components/DKSpaceH";
import ConfirmCustomDomainNamePopup from "./ConfirmCustomDomainNamePopup"
import Loader from "./Loader"

export default class CreateDomainPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Create domain",
            subTitle:
                "In order to publish your page, you need to create a domain for it. We will help you to setup your first domain.",
            createButtonTitle: "Create my domain",
            createCustomDomainButtonTitle: "Create my custom domain",
            domainName: "",
            apiCalled: false,
            waitMsg: "Please wait while we create your domain....",
            domainCreated: false,
            domainURL: "",

            customDomainNameCreated: false,
            customDomainSectionTitle:"Custom Domain Setup - Website",
            customDomainSectionSubTitle: "Map your custom domain (for e.g. website.example.com) with this website.",
            customDomainName:"",

            isCustomDomainVerified: false,

            // customDomainMessage: "",

            shouldShowCustomDomainTextBox: true,
            shouldShowCustomDomainTextBoxCheckBox: true,

            showCustomDomainConfirmationPopup: false,
            showLoadingPopup: false
        };
    }

    domainNameChanged = (e) => {
        if (!this.state.apiCalled) {
            this.setState({
                domainName: e.target.value,
            });
        }
    };

    customDomainNameChanged = (e) => {
        // if (!this.state.apiCalled) {

            this.setState({
                customDomainName: e.target.value,
            });
        // }
    };

    componentDidMount() {
        if (DomainManager.isDomainCreated()) {
            this.setDataIfDomainCreated(false);
        }
    }

    render() {
        return (
            <div
                className="WindowPopup"
                style={{
                    color: "black",
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: "70%",
                    height: "auto",
                }}
            >
                <div style={{ position: "absolute", right: 15, top: 8 }}>
                    {ComponentManager.getButton("X", "gray", this.cancelTapped)}
                </div>
                <div style={{ height: 5 }} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        opacity: 1,
                    }}
                >
                    <div
                        style={{
                            width: "65%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            textAlign: "left",
                            fontSize: 16,
                        }}
                    >
                        <text style={{ fontSize: 25, fontWeight: "bold" }}>
                            {this.state.title}
                        </text>
                        <br />
                        <text>{this.state.subTitle}</text>
                        <br />
                        {this.getDomainNameSection()}
                        {this.getCNameSectionTitle()}
                        {/*<div style={{marginTop: 10, height:1, backgroundColor: 'gray', width:'100%', opacity: 0.3}}></div>*/}
                        {this.getCNameSectionContent()}
                        {/*<div style={{ height: 10 }} />*/}
                        {/*<text style={{ fontSize: 12, color: "gray" }}>*/}
                        {/*    • This will be your website url/link which you will be able to*/}
                        {/*    share with your friends, business partners, colleagues, teams and*/}
                        {/*    family.*/}
                        {/*</text>*/}
                        {/*<text style={{ fontSize: 12, color: "gray", marginTop: 5 }}>*/}
                        {/*    • Once your domain is up and running, you can create and publish*/}
                        {/*    multiple pages on your website.*/}
                        {/*</text>*/}
                        <br />
                    </div>

                    <div
                        style={{
                            width: "35%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <img
                            src={require("./Assets/ic_createDomain.png")}
                            alt=""
                            style={{ width: "90%" }}
                        />
                    </div>
                </div>
                <div className="row" style={{ justifyContent: "center" }}>
                    {!this.state.apiCalled &&
                    ComponentManager.getButton(
                        this.state.createButtonTitle,
                        COLOR_BLUE,
                        this.createDomainTapped
                    )}
                    <DKSpaceH value={10} />
                    {!this.state.apiCalled &&
                    ComponentManager.getButton("Cancel", "tomato", this.cancelTapped)}
                </div>
                {this.state.apiCalled && (
                    <text style={{ fontSize: 13, color: COLOR_BLUE, fontWeight: "700" }}>
                        {this.state.waitMsg}
                    </text>
                )}
                <div className="row" style={{ justifyContent: "center", marginTop: 15 }}>
                    {(this.state.shouldShowCustomDomainTextBox && (!this.state.customDomainNameCreated && this.state.domainCreated)) && ComponentManager.getButton(
                        this.state.createCustomDomainButtonTitle,
                        COLOR_BLUE,
                        this.createCustomDomainTapped
                    )}
                </div>
                <div style={{ height: 15 }} />
                {this.state.showCustomDomainConfirmationPopup && <ConfirmCustomDomainNamePopup
                    onContinueTapped={this.continueTappedInConfirmPopup}
                    onCancelTapped={this.cancelTappedInConfirmPopup}/>}
                {this.state.showLoadingPopup && <Loader/>}
            </div>
        );
    }

    cancelTapped = () => {
        this.props.onCancel();
    };


    validateDomainName = () => {
        if (this.state.domainName === "") {
            alert("Please enter sub-domain name to proceed!");
            return true
        }

        if (this.state.domainName.length < 4 ) {
            alert("Sub-domain is too short");
            return true
        }

        if (!this.isValidDomainName(this.state.domainName)) {
            alert("Sub-domain must consist of alphanumeric words starting with alphabet. Should not have any special characters and space between words.");
            return true
        }

    }

    validateCustomDomainName =() => {
        if (this.state.customDomainName === "" || this.state.customDomainName === null) {
            alert("Custom Domain is required");
            return true
        }

        if (!this.isValidCustomDomainName(this.state.customDomainName)) {
            alert("Enter valid Custom domain");
            return true
        }

    }

    createDomainTapped = () => {
        if (this.validateDomainName()) {
            return
        } else {
            if (this.state.shouldShowCustomDomainTextBox) {
                if (this.validateCustomDomainName()) {
                    return
                } else {
                    this.setState({showCustomDomainConfirmationPopup : true})
                    return
                }
            } else {
                this.continueTappedInConfirmPopup()
            }
        }
    };


    createCustomDomainTapped = () => {

        if (this.state.customDomainName === ""|| this.state.customDomainName === null) {
            alert("Custom Domain is required");
            return;
        }

        let isCustomDomainNameValid = this.isValidCustomDomainName(this.state.customDomainName)

        if (!isCustomDomainNameValid) {
            let commonErrorMessage = "Enter valid custom domain";
            alert(commonErrorMessage);
            return;
        }
        this.setState({showCustomDomainConfirmationPopup : true})
        return
    };

    continueTappedInConfirmPopup = () => {
        // alert('shouldShowCustomDomainTextBox: ' + this.state.shouldShowCustomDomainTextBox + ' customDomainName: ' + this.state.customDomainName)
        // return
        this.setState({showCustomDomainConfirmationPopup : false, showLoadingPopup: true})
        let title = WebsiteManager.getWebsiteTitle();
        let logo = WebsiteManager.getWebsiteLogo()
            ? WebsiteManager.getWebsiteLogo()
            : "";
        let url = WebsiteManager.getWebsiteURL();
        let subDomain = WebsiteManager.getWebsiteDomain().length > 0 ? WebsiteManager.getWebsiteDomain() : this.state.domainName
        let customDomain = this.state.shouldShowCustomDomainTextBox ? this.state.customDomainName : "";
        WebsiteApiManager.updateWebsite(
            title,
            logo,
            url,
            subDomain.toLowerCase(),
            customDomain.toLowerCase(),
            this
        );
    };

    cancelTappedInConfirmPopup = () => {
        this.setState({showCustomDomainConfirmationPopup : false})
    };

    isValidDomainName(domainName) {
        var format1 = /^[a-z]/;
        var format2 = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
        return format1.test(domainName) && !format2.test(domainName);
    }

    isValidCustomDomainName(domainName) {
        var format1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        return format1.test(domainName);
    }

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    getDomainNameSection() {
        if (this.state.domainCreated) {
            return(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 15,
                        marginBottom: 25,
                    }}
                >
                    <a href={this.state.domainURL}>{this.state.domainURL}</a>
                </div>
            )
        } else {
            return(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "85%",
                        marginTop: 5,
                        marginBottom: 20,
                    }}
                >
                    <text>https:// </text>
                    <input
                        className="TextField"
                        style={{ marginBottom: 0, marginLeft: 5, marginRight: 5 }}
                        placeholder="sub-domain name"
                        type="text"
                        value={this.state.domainName}
                        onChange={this.domainNameChanged}
                    />
                    <text>{ApiConstants.SUB_DOMAIN}</text>
                </div>
            )
        }
    }

    getCNameSectionTitle() {
        return(
            <div className="ColumnDiv" style={{marginTop: 25}}>
                <div className="Row" >
                    <text style={{fontWeight: "bold" }}> {this.state.customDomainSectionTitle} </text>
                    {this.state.shouldShowCustomDomainTextBoxCheckBox && <input type="checkbox"  checked={this.state.shouldShowCustomDomainTextBox} style={{marginLeft: 10}} onClick={ () => {
                        let textboxVisibilityToUpdate = this.state.shouldShowCustomDomainTextBox
                        this.setState({shouldShowCustomDomainTextBox: !textboxVisibilityToUpdate})
                    }}/>}
                </div>
                <text style={{marginTop: 15}}> {this.state.customDomainSectionSubTitle} </text>

            </div>

        )
    }
    getCNameSectionContent() {
        if (this.state.customDomainNameCreated) {
            if (this.state.isCustomDomainVerified) {
                return(this.isCustomDomainVerifiedText())
            } else {
                return( this.getCustomDomainNameExistsText())
            }
        } else {
            return( this.state.shouldShowCustomDomainTextBox && this.getCustomDomainNameTextBox())
        }
    }

    isCustomDomainVerifiedText = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    marginBottom: 25,
                }}
            >

                <div className="RowDiv" style={{backgroundColor: '#EEEEEE', padding: 15, borderLeft: '3px solid rgba(242, 156, 56, 1)'}}>
                    <div style={{fontSize: 13}}>
                        <text>Congratulations!! </text>
                        <br/>
                        <br/>
                        <text>Your custom domain </text>
                        <text style={{fontWeight: 'bold'}}> {this.state.customDomainName} </text>
                        <text> has been successfully mapped with </text>
                        <text style={{fontWeight: 'bold'}}> website1.deskera.com </text>
                    </div>
                </div>
            </div>
        )
    }

    getCustomDomainNameExistsText = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 15,
                    marginBottom: 25,
                }}
            >

                <div className="RowDiv" style={{backgroundColor: '#EEEEEE', paddingTop: 15, paddingBottom: 15, borderLeft: '3px solid rgba(242, 156, 56, 1)'}}>
                    <div style={{paddingRight: 10, justifyContent:'top'}}>
                        <img
                            src={require("./Assets/ic_warning.png")}
                            alt=""
                            style={{ width: "90%" }}
                        />
                    </div>
                    <div style={{fontSize: 13}}>
                        <text>If you have not done the setup already, go to your Domain's DNS control panel and add a CNAME record for </text>
                        <text style={{fontWeight: 'bold'}}> {this.state.customDomainName} </text>
                        <text> pointing to </text>
                        <text style={{fontWeight: 'bold'}}> website1.deskera.com </text>
                        <text> with TTL of minimum 300 seconds.</text>
                        <br/>
                        <br/>
                        <text>The DNS changes may take a few minutes to 4-6 hours to reflect. Please check again after some time for the status.</text>

                    </div>
                 </div>
            </div>
        )
    }

    getCustomDomainNameTextBox = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "85%",
                    marginTop: 15,
                    marginBottom: 20,
                }}
            >
                <input
                    className="TextField"
                    style={{ marginBottom: 0, marginLeft: 5, marginRight: 5 }}
                    placeholder="Custom Domain Name"
                    type="text"
                    value={this.state.customDomainName}
                    onChange={this.customDomainNameChanged}
                />
            </div>
        )
    }

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    //Update is create, To create domain you need to update website subdomain detail
    websiteUpdatedSuccess(websiteDetails) {
        WebsiteManager.setWebsiteDetails(websiteDetails);
        DomainManager.domainCreated(websiteDetails.subdomain);
        if (websiteDetails.domain !== undefined) {
            DomainManager.customDomainCreated(websiteDetails.domain);
        }
        this.setState({showLoadingPopup : false}, () => {
            this.setDataIfDomainCreated();
            this.props.onDomainCreate();
        })

    }
    websiteUpdateFailed() {
        this.setState({
            apiCalled: true,
            showLoadingPopup : false
        });
    }

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    setDataIfDomainCreated(calledOnCreate = true) {
        var msg = calledOnCreate
            ? "Your sub-domain has been created successfully. Now you can publish it to make it available to end-users. Your sub-domain url is:"
            : "Your sub-domain has been created. It's available to end-users. Below is your sub-domain url:";
        var str_link = DomainManager.getDomainURL();
        var customDomainName = DomainManager.getCustomDomainName();
        this.setState({
            apiCalled: true,
            waitMsg: calledOnCreate
                ? ""
                : "",
            title: calledOnCreate ? "Congratulations!" : "Website is published!",
            subTitle: msg,
            domainCreated: true,
            domainURL: str_link,
            customDomainNameCreated: DomainManager.isCustomDomainCreated(),
            customDomainName: customDomainName,
            shouldShowCustomDomainTextBoxCheckBox: !DomainManager.isCustomDomainCreated(),
            isCustomDomainVerified : DomainManager.verifyCustomDomain()
        });
    }
}
