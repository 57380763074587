import ApiConstants from "./ApiConstants";

export default class AppManager {
  static didUserLoggedIn = false;
  static didDataSaved = true;

  static userLoggedIn() {
    AppManager.didUserLoggedIn = true;
  }
  static isUserLoggedIn() {
    return AppManager.didUserLoggedIn;
  }

  static gotoLoginPage() {
    window.open(
      ApiConstants.URL.LOGIN_REDIRECT + window.location.href,
      "_self"
    );
  }

  static isDataSaved() {
    return this.didDataSaved;
  }
  static dataSaved() {
    AppManager.didDataSaved = true;
  }
  static dataNotSaved() {
    AppManager.didDataSaved = false;
  }

  static isWebURLValid(url) {
    let checkForHTTP_str = url.substring(0, 7);
    let checkForHTTPS_str = url.substring(0, 8);
    if (checkForHTTP_str === "http://" || checkForHTTPS_str === "https://") {
      return true;
    }
    return false;
  }

  static openWebURL(url, target = "_self") {
    if (!AppManager.isWebURLValid(url)) {
      url = "https://" + url;
    }
    window.open(url, target);
  }
}
