export default class Template7 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "left",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892039229_hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Summer Collection 25% OFF",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892793654_product-01%402x.png",
            heading: "Black Genuine Leather Bags",
            text: "$ 23.20",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892813760_product-02%402x.png",
            heading: "Fashion Red Leather Bags",
            text: "$ 20.16",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892848159_product-03%402x.png",
            heading: "Fashion Genuine Leather Bags",
            text: "$ 30.14",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892881294_product-04%402x.png",
            heading: "Simply Leather Bags",
            text: "$ 15.20",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892909114_product-05%402x.png",
            heading: "New Fashion Leather Bags",
            text: "$ 25.20",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604892932391_product-06%402x.png",
            heading: "Single Hand Models Bags",
            text: "$ 17.6",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 5,
        paddingHorizontal: 12,
      },
      {
        id: 3,
        type: "Image with text",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893064108_section-image-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Our Brands",
        isImageHidden: false,
        text:
          "n porttitor elit ac mi placerat hendrerit. Morbi accumsan, erat iullamcorper consectetur, nisi erat efficitur eros, sagittis tristique odio turpis vitae dui. Vestibulum gravida est ut erat pretium, non pretium diam feugiat. Integer egestas suscipit lorem ac fermentum. Integer at mauris vitae justo sagittis dapibus. Pellentesque sit amet nisl vitae orci vehicula faucibus. Fusce et lat, feugiat est.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 4,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "Our Showcase",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893292104_showcase-01%402x.png",
            heading: "Fashion Genuine Chain Watch",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893307044_showcase-02%402x.png",
            heading: "Fashion Silver Chain Watch",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893317427_showcase-03-1%402x.png",
            heading: "Fashion Chain Watch",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893349171_showcase-04-1%402x.png",
            heading: "Fashion Black Chain Watch",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893368649_showcase-05%402x.png",
            heading: "Fashion Lether Watch",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604893383479_showcase-06-1%402x.png",
            heading: "Fashion Forign Watch",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 3,
        paddingHorizontal: 12,
      },
      {
        id: 5,
        type: "News Letter",
        backgroundColor: "#9673f7",
        heading: "News Letter!",
        text: "",
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        paddingVertical: 4,
        paddingHorizontal: 2,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
