export default class Template12 {
  static getData() {
    return [
      {
        id: 1,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "large",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "We Create Space",
        isImageHidden: false,
        text:
          "\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995085019_header-section%402x-min.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "A Large Journey Ahead",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 3,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995153017_section-image-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "01.\u000bFeels like home",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#262626",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995198656_section-image-02%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "02. Colorful Architect",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 5,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995289775_section-03%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "03. Traditional in \u000bDesign",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "What made us stand out",
        isImageHidden: false,
        text:
          "\nLorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor. Lorem",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "center",
        textSize: "small",
        textColor: "#ffffff",
        buttonColor: "#000000",
        imageShape: "circular",
        text: "FREE CONSULTATION",
        paddingVertical: 0,
        paddingHorizontal: 2,
        webURL: "",
      },
      {
        id: 11,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995480009_Screenshot%202020-11-10%20at%204.03.52%20PM.png",
            heading: "Unique",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995494736_Screenshot%202020-11-10%20at%204.04.00%20PM.png",
            heading: "Real",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604995808141_Screenshot%202020-11-10%20at%204.04.07%20PM.png",
            heading: "Real",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 12,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 13,
        type: "Paragraph with title",
        backgroundColor: "#f6f6f6",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
