import React, { Component } from "react";
import CartManager from "./Managers/CartManager";
import StoreManager from "./Managers/StoreManager";
import DKUtility from "../DKUtility";

export default class ProductRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="column border-s border-radius-m"
        style={{ width: "95%", padding: 12 }}
      >
        <div className="row">
          <img
            src={
              this.props.data.images && this.props.data.images.length > 0
                ? decodeURIComponent(this.props.data.images[0])
                : require("../Assets/ic_thumbnail_default.png")
            }
            alt=""
            style={{ width: 100 }}
          />
          <div className="row">
            <div className="row">
              <div
                className="column parent-width"
                style={{
                  padding: 12,
                  paddingLeft: this.props.isFromPopup ? 0 : 12,
                  overflowY: "hidden",
                }}
              >
                <text
                  className="fw-b text-align-left"
                  style={{ fontSize: this.props.isFromPopup ? 18 : 15 }}
                >
                  {this.props.data.name}
                </text>
                <text
                  className="text-align-left text-gray"
                  style={{
                    marginTop: 4,
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    wordWrap: "breakWord",
                    overflow: "hidden",
                    maxHeight: "3.6em",
                    lineHeight: "1.8em",
                  }}
                >
                  {this.props.data.description}
                </text>
                {this.getQuantityRow()}
                <button
                  className="cursor-hand"
                  style={{
                    marginTop: 15,
                    backgroundColor: "rgb(213, 9, 19)",
                    color: "white",
                    border: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    borderRadius: 4,
                    outline: "none",
                  }}
                  onClick={() =>
                    this.props.onDelete(this.props.index, this.props.data)
                  }
                >
                  Remove
                </button>
              </div>
              <text
                className="fw-b text-align-left"
                style={{
                  marginTop: 10,
                  fontSize: this.props.isFromPopup ? 22 : 15,
                  wordWrap: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                {DKUtility.getCurrencySymbol(StoreManager.getStoreCurrency()) +
                  " " +
                  DKUtility.toCurrencyFormat(
                    parseFloat(this.props.data.quantity) *
                      parseFloat(this.props.data.sellingPrice)
                  )}
              </text>
            </div>
          </div>
        </div>
      </div>
    );
  }

  addToCartTapped = (index, data) => {
    CartManager.addProduct(data);
    // alert("Item added to cart!");
  };

  getQuantityRow() {
    return (
      <div
        className="row align-items-center"
        style={{
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <text
          className="text-align-left fw-b"
          style={{
            fontSize: 12,
          }}
        >
          {"Qty "}
        </text>
        <button
          className="cursor-hand"
          style={{ marginLeft: 10, marginRight: 8 }}
          onClick={() =>
            this.props.onQuantityMinus(this.props.index, this.props.data)
          }
        >
          -
        </button>
        <text
          className="text-align-left fw-b"
          style={{
            fontSize: 12,
          }}
        >
          {this.props.data.quantity}
        </text>
        <button
          className="cursor-hand"
          style={{ marginLeft: 8 }}
          onClick={() =>
            this.props.onQuantityPlus(this.props.index, this.props.data)
          }
        >
          +
        </button>
      </div>
    );
  }
}
