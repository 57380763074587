export default class Template9 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "medium",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604900615793_hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "YOUR HOUSEY - YOUR LIFE",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604900693621_section-image%3D01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "01 introduction",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604900748224_section-image-02%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "02 luxurious",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#e8e9e5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604900770521_section-image-03%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "03 nice location",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 5,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "medium",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604900869351_sedtion-bg-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "LET'S START YOUR NEW LIFE RIGHT NOW",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 8,
        type: "Image with text",
        backgroundColor: "#feffe9",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604901611160_Screenshot%202020-11-09%20at%201.59.02%20PM.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "04 COMFORTFUL",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Image with text",
        backgroundColor: "#feffe9",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604901689387_Screenshot%202020-11-09%20at%201.59.19%20PM.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "05 FEEL GREAT",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "News Letter",
        backgroundColor: "#1d211f",
        heading: "News Letter!",
        text: "Please subscribe to get updates from our shop",
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
