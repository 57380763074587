export default class Template6 {
  static getData() {
    return [
      {
        id: 1,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Nike Air Force One MID id",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "center",
        textSize: "small",
        textColor: "#ffffff",
        buttonColor: "#444444",
        imageShape: "circular",
        text: "Buy Now",
        paddingVertical: 0,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 3,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657339019_showcase-hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 6,
        paddingHorizontal: 18,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#f5f5f5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657543031_showcase-section-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Air inside",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 5,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "Popular Arrivals",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657727395_product-01%402x.png",
            heading: "Green Black Sneakers",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657741952_product-02%402x.png",
            heading: "Black Nike 23",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657757424_product-03%402x.png",
            heading: "Sport Shoes",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604657804991_product-04%402x.png",
            heading: "White Sport",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Blogs column",
        backgroundColor: "#f5f5f5",
        heading: "What makes us the best in our Industry",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604658380045_Screenshot%202020-11-06%20at%206.25.03%20PM.png",
            heading: "Light weight",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor \n",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604658404339_Screenshot%202020-11-06%20at%206.25.13%20PM.png",
            heading: "Fast & Flexible",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor \n",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604658415426_Screenshot%202020-11-06%20at%206.25.48%20PM.png",
            heading: "Tough & Durable",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor \n",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#222222",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#222222",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "We Thought of you",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Paragraph with title",
        backgroundColor: "#222222",
        sectionHeight: null,
        textSize: "small",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text:
          "Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
        paddingVertical: 0,
        paddingHorizontal: 14,
      },
      {
        id: 10,
        type: "Paragraph with title",
        backgroundColor: "#222222",
        sectionHeight: null,
        textSize: "small",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Nandy Sweety\nUI Designer",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "News Letter",
        backgroundColor: "#ffffff",
        heading: "Get connected with Us",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 14,
      },
      {
        id: 12,
        type: "Paragraph with title",
        backgroundColor: "#f5f5f5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
