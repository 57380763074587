import Template0 from "./Template0";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";
import Template10 from "./Template10";
import Template11 from "./Template11";
import Template14 from "./Template14";
import Template15 from "./Template15";
import Template16 from "./Template16";
import Template17 from "./Template17";
import Template18 from "./Template18";
import Template19 from "./Template19";
import Template12 from "./Template12";
import Template13 from "./Template13";

export default class TemplateManager {
  static getAllTemplates() {
    return [
      {
        id: 0,
        thumbnailImage: require("../Assets/Templates/ic_template_0.png"),
      },
      {
        id: 1,
        thumbnailImage: require("../Assets/Templates/ic_template_1.png"),
      },
      {
        id: 2,
        thumbnailImage: require("../Assets/Templates/ic_template_2.png"),
      },
      {
        id: 3,
        thumbnailImage: require("../Assets/Templates/ic_template_3.png"),
      },
      {
        id: 4,
        thumbnailImage: require("../Assets/Templates/ic_template_4.png"),
      },
      {
        id: 5,
        thumbnailImage: require("../Assets/Templates/ic_template_5.png"),
      },
      {
        id: 6,
        thumbnailImage: require("../Assets/Templates/ic_template_6.png"),
      },
      {
        id: 7,
        thumbnailImage: require("../Assets/Templates/ic_template_7.png"),
      },
      {
        id: 8,
        thumbnailImage: require("../Assets/Templates/ic_template_8.png"),
      },
      {
        id: 9,
        thumbnailImage: require("../Assets/Templates/ic_template_9.png"),
      },
      {
        id: 10,
        thumbnailImage: require("../Assets/Templates/ic_template_10.png"),
      },
      {
        id: 11,
        thumbnailImage: require("../Assets/Templates/ic_template_11.png"),
      },
      {
        id: 12,
        thumbnailImage: require("../Assets/Templates/ic_template_12.png"),
      },
      {
        id: 13,
        thumbnailImage: require("../Assets/Templates/ic_template_13.png"),
      },
      {
        id: 14,
        thumbnailImage: require("../Assets/Templates/ic_template_14.png"),
      },
      {
        id: 15,
        thumbnailImage: require("../Assets/Templates/ic_template_15.png"),
      },
      {
        id: 16,
        thumbnailImage: require("../Assets/Templates/ic_template_16.png"),
      },
      {
        id: 17,
        thumbnailImage: require("../Assets/Templates/ic_template_17.png"),
      },
      {
        id: 18,
        thumbnailImage: require("../Assets/Templates/ic_template_18.png"),
      },
    ];
  }

  static getTemplateData(templateId) {
    switch (templateId) {
      case 0:
        return Template0.getData();
      case 1:
        return Template1.getData();
      case 2:
        return Template2.getData();
      case 3:
        return Template3.getData();
      case 4:
        return Template4.getData();
      case 5:
        return Template5.getData();
      case 6:
        return Template6.getData();
      case 7:
        return Template7.getData();
      case 8:
        return Template8.getData();
      case 9:
        return Template9.getData();
      case 10:
        return Template10.getData();
      case 11:
        return Template11.getData();
      case 12:
        return Template12.getData();
      case 13:
        return Template13.getData();
      case 14:
        return Template14.getData();
      case 15:
        return Template15.getData();
      case 16:
        return Template16.getData();
      case 17:
        return Template17.getData();
      case 18:
        return Template18.getData();
      case 19:
        return Template19.getData();
      default:
        return [];
    }
  }
}
