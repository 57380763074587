export default class Template16 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#fdf0e2",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604935178621_hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "We Provide Best Digital Marketing Solutions",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 2,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Let's Check Our Featured Services",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604935633096_Screenshot%202020-11-09%20at%2011.27.00%20PM.png",
            heading: "Marketing Analysis",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604935383183_Screenshot%202020-11-09%20at%2011.21.39%20PM.png",
            heading: "Website Optimization",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604935393197_Screenshot%202020-11-09%20at%2011.21.46%20PM.png",
            heading: "Email Marketing",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Image with text",
        backgroundColor: "#fdf0e2",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604935500273_section-image%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Enjoy full-service Digital Marketing Expertise",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Let's Check Our Services",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "circular",
        isImageHidden: false,
        columns: [
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991360315_service-01%402x.png",
            heading: "Social Media Marketing",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991348570_service-02%402x.png",
            heading: "Keyword Research",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991372393_service-03%402x.png",
            heading: "Content Marketing",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991382651_service-04%402x.png",
            heading: "PPC Advertising",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991394016_service-05%402x.png",
            heading: "Competitor Research",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991457974_service-06%402x.png",
            heading: "Skyrocketing Growth",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 2,
        paddingHorizontal: 12,
      },
      {
        id: 8,
        type: "Image with text",
        backgroundColor: "#fdf0e2",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604991671727_section-image-02%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "What Our Clients Are Saying?",
        isImageHidden: false,
        text:
          "\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 9,
        type: "News Letter",
        backgroundColor: "#ffffff",
        heading: "Subscribe To Our Newsletter",
        text:
          "Lorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor.",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 10,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
