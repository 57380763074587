export default class Template14 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604998620558_hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "BID & SEIZE DEAL",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 3,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Select Category",
        isImageHidden: false,
        text:
          "\nLorem ipsum dolor sit amet, consectetur adipiscing elit, \nsed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 4,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604998891981_category-01%402x.png",
            heading: "Luxury",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604998900268_category-02%402x.png",
            heading: "Classic",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604998878037_category-03%402x.png",
            heading: "W Class",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 5,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Button",
        backgroundColor: "#f6f6f6",
        textAlignment: "center",
        textSize: "small",
        textColor: "#000000",
        buttonColor: "#ffffff",
        imageShape: "circular",
        text: "VIEW ALL",
        paddingVertical: 2,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 7,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999046405_section-image-01%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Untouched Masterpieces",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 2,
        paddingHorizontal: 0,
      },
      {
        id: 8,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999162249_section-image-02%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Digitaly Precise",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 9,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999211923_Screenshot%202020-11-10%20at%205.06.38%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 10,
        type: "Blogs column",
        backgroundColor: "#f5f5f5",
        heading: "Testimonials",
        text: "",
        textSize: "medium",
        textColor: "#222222",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999398682_Screenshot%202020-11-10%20at%205.09.26%20PM.png",
            heading: "Kurt Predovic",
            text:
              "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999412860_Screenshot%202020-11-10%20at%205.09.36%20PM.png",
            heading: "Santos Walker",
            text:
              "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604999423802_Screenshot%202020-11-10%20at%205.09.48%20PM.png",
            heading: "Jared Weissnat",
            text:
              "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Paragraph with title",
        backgroundColor: "#071a41",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
