export default class Template4 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604650640808_restaurant-hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Save your Hunger",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Paragraph with title",
        backgroundColor: "#fff5e4",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "CHOOSE & ENJOY",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Blogs column",
        backgroundColor: "#fff5e4",
        heading: "Blogs",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604652968292_product-image-4%402x.png",
            heading: "LOREM IPSUM DOLOR",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604652934445_product-image-2%402x.png",
            heading: "LOREM IPSUM DOLOR",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604653025858_product-image-3%402x.png",
            heading: "LOREM IPSUM DOLOR",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Button",
        backgroundColor: "#fff5e4",
        textAlignment: "center",
        textSize: "medium",
        textColor: "#ffffff",
        buttonColor: "#fd633d",
        imageShape: "cornerRadius",
        text: "Try now",
        paddingVertical: 2,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 5,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "UPCOMING EVENTS",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 6,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Photo Gallery",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604653355505_Image%2041%402x.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604653442625_Image%2043%402x.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604653486698_Image%2042%402x.png",
            heading: "",
            text: "",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 9,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "large",
        textAlignment: "left",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604653697104_restaurant-section-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "MOST POPULAR DISHES",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 10,
        type: "News Letter",
        backgroundColor: "#ffffff",
        heading: "BOOK YOUR TABLE",
        text: "Enter your email and book a table tonight!",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
