export default class Template3 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#5005b4",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647066963_ts-hero-image-phone%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "A smartphone than knows you ",
        isImageHidden: false,
        text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image: null,
            heading: "BEST SELLER",
            text: "These are all our best selling products in this year",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "1",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647645560_laptop-2%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647657039_laptop-3%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647663006_laptop-4%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647669434_laptop-5%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647674637_laptop-6%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647681918_laptop-7%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647691572_laptop-8%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647703474_latest-new-02%402x.png",
            heading: "Apple Macbook Pro",
            text: "$499",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "center",
        textSize: "small",
        textColor: "#33a0ff",
        buttonColor: "#ffffff",
        imageShape: "square",
        text: "LOAD MORE",
        paddingVertical: 2,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 6,
        type: "Image with text",
        backgroundColor: "#2b004f",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647741815_ts-section-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "iPhone 5c",
        isImageHidden: false,
        text:
          "Performance and design. Taken right to the edge. Performance and design. Taken right to the edge.Performance and design. Taken right to the edge.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 7,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604648248847_Screenshot%202020-11-06%20at%203.36.23%20PM.png",
            heading: "FREE SHIPPING",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604648269048_Screenshot%202020-11-06%20at%203.36.42%20PM.png",
            heading: "100% REFUND",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604648258113_Screenshot%202020-11-06%20at%203.36.54%20PM.png",
            heading: "SUPPORT 24/7",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 10,
        type: "Testimonials",
        backgroundColor: "#f5f5f5",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Testimonials",
        backgroundColor: "#f5f5f5",
        heading: "LATEST NEWS",
        text: "",
        textSize: "small",
        textColor: "#22262a",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Blogs column",
        backgroundColor: "#f5f5f5",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#22262b",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647713324_latest-news-01%402x.png",
            heading: "Typesetting industry",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647703474_latest-new-02%402x.png",
            heading: "Typesetting industry",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604647725378_latest-news-03%402x.png",
            heading: "Typesetting industry",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Testimonials",
        backgroundColor: "#f5f5f5",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 12,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "PEOPLE LOVE US!",
        text: "",
        textSize: "small",
        textColor: "#22262a",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image: null,
            heading: "John Doe, Mentor",
            text:
              "Add customer reviews and testimonials to showcase your store’s happy customers.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image: null,
            heading: "Lisa Mathew, Acme",
            text:
              "Add customer reviews and testimonials to showcase your store’s happy customers.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image: null,
            heading: "Jolly James, Flora",
            text:
              "Add customer reviews and testimonials to showcase your store’s happy customers.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 14,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
