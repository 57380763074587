import ApiConstants from "../../ApiConstants";
import ApiManager from "../../ApiManager";
import CartManager from "../Managers/CartManager";

/*
CALLBACKS -
1. orderPlacedSuccess()
2. orderPlaceFailed()
*/

export default class CheckoutApiClient {
  static placeOrder(presenter, data) {
    fetch(
      ApiConstants.URL.BASE_CONSTANT + ApiConstants.URL.CHECKOUT_API,
      ApiManager.getApiRequestOptions("POST", data)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.errorMessage);
          presenter.orderPlaceFailed();
        } else {
          CartManager.setOrderNumber(data.code);
          presenter.orderPlacedSuccess();
        }
      })
      .catch((error) => {
        alert("Internal server error occured. Try again later.");
        presenter.orderPlaceFailed();
      });
  }

  static getStripeIntent(data, successCallback, failureCallback) {
    fetch(
      ApiConstants.URL.BASE_CONSTANT +
        ApiConstants.URL.STRIPE_INTENT +
        "?tenantId=" +
        ApiConstants.TENANT_ID +
        "&orderId=1",
      ApiManager.getApiRequestOptions("POST", data)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (parseInt(data.error) === 400) {
            alert(data.message);
            // alert(
            //   "Payment not completed. It seems like merchant has not yet setup his/her Stripe billing account."
            // );
          } else {
            alert(JSON.stringify(data));
          }
          failureCallback();
        } else {
          CartManager.setOrderNumber(data.code);
          successCallback(data.secret);
        }
      })
      .catch((error) => {
        alert("Internal server error occured. Try again later.");
        failureCallback();
      });
  }
}
