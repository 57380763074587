export default class Template2 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#fffaf2",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604643398712_cs-hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "FW 2018 limited edition",
        isImageHidden: false,
        text:
          "Free Worldwide Shipping: get free shipping with our Special Service and not redeemable for cash or credit. See Details",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "large",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 4,
            type: "Column with image and text",
            image: null,
            heading: "Cotton textured sweaters from $14.99",
            text: "",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "left",
        textSize: "small",
        textColor: "#ffffff",
        buttonColor: "#03545c",
        imageShape: "square",
        text: "Button Text",
        paddingVertical: 0,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 4,
        type: "Testimonials",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "Column header",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604643710869_cs-product-1%402x.png",
            heading: "Jumpers & Cardigans",
            text: "Cable knitted neck sweater\n139.00$ ",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604643732904_cs-product-2%402x.png",
            heading: "Jumpers & Cardigans",
            text: "Cable knitted neck sweater\n139.00$ ",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604643747948_cs-product-3%402x.png",
            heading: "Jumpers & Cardigans",
            text: "Cable knitted neck sweater\n139.00$ ",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#fffaf0",
        sectionHeight: null,
        textSize: "large",
        textColor: "#161616",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Winter Collection New 2020",
        isImageHidden: false,
        text:
          "\nFree Worldwide Shipping: get free shipping with our Special Service and not redeemable for cash or credit. See Details",
        paddingVertical: 2,
        paddingHorizontal: 24,
      },
      {
        id: 7,
        type: "Button",
        backgroundColor: "#fffaf3",
        textAlignment: "center",
        textSize: "small",
        textColor: "#ffffff",
        buttonColor: "#03545c",
        imageShape: "square",
        text: "View Collecrtion >",
        paddingVertical: 0,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 8,
        type: "Testimonials",
        backgroundColor: "#fffaf0",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "rgb(60, 60, 60)",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604644697303_cs-collection-section-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "",
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 11,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "We deliver the Best Products.",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [],
        numberOfColumnsPerRow: "1",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 2,
      },
      {
        id: 12,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604645328850_Screenshot%202020-11-06%20at%201.52.49%20PM.png",
            heading: "Free shipping",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604645350427_Screenshot%202020-11-06%20at%201.52.59%20PM.png",
            heading: "100% Refund",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604645371693_Screenshot%202020-11-06%20at%201.53.07%20PM.png",
            heading: "Support 24/7",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor minim veniam, quis nostrud reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 13,
        type: "Testimonials",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 14,
        type: "News Letter",
        backgroundColor: "#264d51",
        heading: "Let’s stay in touch",
        text: "Enjoy 15% off your first order when you join our mailing list.",
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
