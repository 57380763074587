export default class Template13 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#0a2253",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996087042_hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "INVEST FOR BETTER\u000bTOMMOROW",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 2,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam \nnonumy eirmod tempor invidunt ut \nlabore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. \nStet clita kasd gubergren, no sea takimata sanctus est",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "large",
        textColor: "#0864b1",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image: null,
            heading: "+20",
            text: "Lorem Ipsum",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image: null,
            heading: "7",
            text: "Lorem Ipsum",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image: null,
            heading: "2010",
            text: "Lorem Ipsum",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image: null,
            heading: "115",
            text: "Lorem Ipsum",
          },
        ],
        numberOfColumnsPerRow: "4",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Text column with image",
        backgroundColor: "#f5f5f5",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996450126_Screenshot%202020-11-10%20at%204.20.17%20PM.png",
            heading: "",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996464291_Screenshot%202020-11-10%20at%204.20.27%20PM.png",
            heading: "",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996474541_Screenshot%202020-11-10%20at%204.20.36%20PM.png",
            heading: "",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "Latest News",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996581046_news-01%402x.png",
            heading: "Lorem ipsum dolor sit amet, ",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996593527_news-02%402x.png",
            heading: "Lorem ipsum dolor sit amet, ",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996603874_news-03%402x.png",
            heading: "Lorem ipsum dolor sit amet, ",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Blogs column",
        backgroundColor: "#0864b1",
        heading: "Testimonials",
        text: "",
        textSize: "small",
        textColor: "#ffffff",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996799845_Screenshot%202020-11-10%20at%204.26.19%20PM.png",
            heading: "Giovanna Kling",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996816793_Screenshot%202020-11-10%20at%204.26.24%20PM.png",
            heading: "Kathryn Rohan",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114100/1604996828362_Screenshot%202020-11-10%20at%204.26.31%20PM.png",
            heading: "Eino Spencer",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "News Letter",
        backgroundColor: "#0a2253",
        heading: "Subscribe To Our Newsletter",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#071a41",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
