import React, { Component } from "react";
import DKSpaceV from "./eCommerce/Components/DKSpaceV";

export default class Loader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="WindowPopup"
                style={{
                    color: "black",
                    padding: 10,
                    width: 200,
                    height: 'auto'
                }}
            >
                <text style={{ fontSize: 13, fontWeight: 'bold' }}>Loading...</text>
            </div>
        );
    }
}
