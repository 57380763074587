export default class Template19 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905092388_header-hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Travel the World",
        text:
          "\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n\n\n\n\n\n\n\n\n\n\n\n",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905238491_section-image-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "We provide the best facility to help you travel the world",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 3,
        type: "Paragraph with title",
        backgroundColor: "#fafafa",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Our Services",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus. Nunc varius ullamcorper felis. Nulla nibh ipsum, rutrum.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Featured collection",
        backgroundColor: "#fafafa",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905570407_Screenshot%202020-11-09%20at%203.05.49%20PM.png",
            heading: "Peace of Mind",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905664785_Screenshot%202020-11-09%20at%203.06.42%20PM.png",
            heading: "Coffee Breaks",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905690121_Screenshot%202020-11-09%20at%203.06.47%20PM.png",
            heading: "Your product name",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905716129_Screenshot%202020-11-09%20at%203.07.14%20PM.png",
            heading: "Delivery",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905729628_Screenshot%202020-11-09%20at%203.07.20%20PM.png",
            heading: "Inbox",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905748190_Screenshot%202020-11-09%20at%203.07.25%20PM.png",
            heading: "Settings",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 10,
        type: "Paragraph with title",
        backgroundColor: "#fafafa",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604905945268_section-image-02%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Our Travel Guide",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus. Nunc varius ullamcorper felis. Nulla nibh ipsum, rutrum.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#fafafa",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Featured Projects",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus. Nunc varius ullamcorper felis. Nulla nibh ipsum, rutrum.",
        paddingVertical: 6,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604906077894_projects-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Trip to Venice, Italy",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 8,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604906152346_projects-02%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Visit to Paris, Italy",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 9,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604906202301_projects-03%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Travel to Agra, India",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices accumsan ornare. Phasellus tristique ullamcorper luctus.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 11,
        type: "Paragraph with title",
        backgroundColor: "#071a41",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
