import React, { Component } from "react";
import ComponentManager from "./ComponentManager";
import { COLOR_BLUE } from "./Constants";
import DKSpaceH from "./eCommerce/Components/DKSpaceH";
import DKSpaceV from "./eCommerce/Components/DKSpaceV";

export default class ConfirmCustomDomainNamePopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="WindowPopup"
                style={{
                    color: "black",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingBottom: 10,
                    width: 450,
                    height: "auto",
                }}
            >
                <div className="ColumnDiv" style={{alignItems: 'center'}}>
                    <text style={{ fontSize: 25, fontWeight: "bold" }}>Warning</text>
                    <DKSpaceV value={10} />
                    <text> Custom Domain is a one time setup and can not be changed once configured. Do you want to continue? </text>
                </div>


                <div className="RowReverseDiv" style={{ justifyContent: "center", marginTop: 15 }}>
                    {ComponentManager.getButton(
                        "Continue",
                        COLOR_BLUE,
                        this.onContinueTapped
                    )}
                    <DKSpaceH value={10} />
                    {ComponentManager.getButton(
                        "Cancel",
                        "red",
                        this.onCancelTapped
                    )}
                </div>

            </div>
        );
    }

    onCancelTapped = () => {
        this.props.onCancelTapped();

    };

    onContinueTapped = () => {
        this.props.onContinueTapped();
    };
}
