export default class Template10 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "left",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604902674054_Screenshot%202020-11-09%20at%202.17.39%20PM.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Get busiess every business solution with us.",
        text:
          "Power your business to new heights with \nour award-winning digital marketing \nservices and technology platform.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Button",
        backgroundColor: "#233761",
        textAlignment: "left",
        textSize: "small",
        textColor: "#000000",
        buttonColor: "#ffffff",
        imageShape: "circular",
        text: "Get Started",
        paddingVertical: 2,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 3,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "Blogs",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604903974129_Screenshot%202020-11-09%20at%202.39.00%20PM.png",
            heading: "Grow your traffic",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904100231_Screenshot%202020-11-09%20at%202.41.29%20PM.png",
            heading: "Get quality leads",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904015263_Screenshot%202020-11-09%20at%202.39.20%20PM.png",
            heading: "Drive more sales",
            text:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 8,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904149299_section-01%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Give your team the best opportunity.",
        isImageHidden: false,
        text:
          "Power your business to new heights with our award-winning digital marketing services and technology platform.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904240860_Screenshot%202020-11-09%20at%202.43.51%20PM.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Our numbers",
        isImageHidden: false,
        text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        paddingVertical: 0,
        paddingHorizontal: 8,
      },
      {
        id: 6,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904342585_Screenshot%202020-11-09%20at%202.45.33%20PM.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Increase your Performance with us",
        isImageHidden: false,
        text:
          "Power your business to new heights with our award-winning digital marketing services and technology platform.",
        paddingVertical: 2,
        paddingHorizontal: 8,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#233761",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "From Blog",
        isImageHidden: false,
        text:
          "\nPower your business to new heights with our award-winning \n\ndigital marketing services and technology platform.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Blogs column",
        backgroundColor: "#233761",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904433301_blog-01%402x.png",
            heading: "",
            text:
              "Power your business to new heights with our award-winning digital marketing services and technology platform.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904451977_blog-02%402x.png",
            heading: "",
            text:
              "Power your business to new heights with our award-winning digital marketing services and technology platform.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604904461163_blog-03%402x.png",
            heading: "",
            text:
              "Power your business to new heights with our award-winning digital marketing services and technology platform.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Paragraph with title",
        backgroundColor: "#233761",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 10,
        type: "News Letter",
        backgroundColor: "#fcbc14",
        heading: "Get the pest project estimation",
        text: "",
        textSize: "small",
        textColor: "#ffffff",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
