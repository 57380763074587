export default class WebsiteManager {
  static didWebsiteCreated = false;
  static websiteDetails = null;

  static setWebsiteDetails(data) {
    WebsiteManager.websiteDetails = data;
  }
  static getWebsiteDetails() {
    return WebsiteManager.websiteDetails;
  }

  static isWebsiteCreated() {
    return WebsiteManager.didWebsiteCreated;
  }

  static websiteCreated(data) {
    WebsiteManager.didWebsiteCreated = true;
    WebsiteManager.setWebsiteDetails(data);
  }

  static getWebsiteID() {
    return WebsiteManager.websiteDetails.id;
  }
  static getWebsiteTitle() {
    return WebsiteManager.websiteDetails.title;
  }
  static getWebsiteURL() {
    return WebsiteManager.websiteDetails.url;
  }
  static getWebsiteDomain() {
    return WebsiteManager.websiteDetails.subdomain;
  }
  static getWebsiteLogo() {
    return WebsiteManager.websiteDetails.logo_image;
  }
  static getWebsiteCustomDomain() {
    return WebsiteManager.websiteDetails.domain;
  }
}
