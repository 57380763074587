import ApiConstants from "../../ApiConstants";
import ApiManager from "../../ApiManager";

/*
CALLBACKS - 
1. productsReceiveSuccess(data)
2. productsReceiveFailed()
*/

export default class ProductApiClient {
  static getAllProducts(presenter) {
    fetch(
      ApiConstants.URL.BASE_CONSTANT +
        ApiConstants.URL.PRODUCT_CRUD_API +
        "?tenantId=" +
        ApiConstants.TENANT_ID,
      ApiManager.getApiRequestOptions("GET")
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.errorMessage);
          presenter.productsReceiveFailed();
        } else {
          data.map((obj) => {
            obj["quantity"] = 1;
            return obj;
          });
          let listedProducts = data.filter((obj) => obj.isListed === true);
          presenter.productsReceiveSuccess(listedProducts.reverse());
        }
      })
      .catch((error) => {
        // alert("Internal server error occured. Try again later.");
        presenter.productsReceiveFailed();
      });
  }
}
