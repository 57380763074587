export default class Template15 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604914944658_hero-image%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Balance Your Body and Mind",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "left",
        textSize: "large",
        textColor: "#000000",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915304997_4-2%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Pregnant Women Program",
        text:
          "Lorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 3,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915573092_program-01%402x.png",
            heading: "Program 1",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915696607_program-03%402x.png",
            heading: "Program 2",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915708523_program-03%402x.png",
            heading: "Program 3",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915035780_section-image-1%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Yoga Breathing or Pranayama",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 5,
        type: "Image with text overlay",
        backgroundColor: "#000000",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915891233_4-7%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Header text",
        text:
          "Use overlay text to give your customers insight into your brand. Select imagery and text that relates to your style and story.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Gallery",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur \nadipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Photo Gallery",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604934309002_gallery-01%402x.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604934335906_gallery-02%402x.png",
            heading: "",
            text: "",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604934369718_gallery-03%402x.png",
            heading: "",
            text: "",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Image with text",
        backgroundColor: "#e5ccc6",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604915035780_section-image-1%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "Yoga Training Showreel",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 10,
        type: "News Letter",
        backgroundColor: "#ffffff",
        heading: "Get in Touch",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Paragraph with title",
        backgroundColor: "#e5ccc6",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
