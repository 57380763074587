import ApiConstants from "./ApiConstants";

export default class DomainManager {
  static didDomainCreated = false;
  static domainName = null;
  static presenter = null;

  static didCustomDomainCreated = false;
  static customDomainName = null;

  static isCustomDomainVerified = false;

  static isDomainCreated() {
    return DomainManager.didDomainCreated;
  }

  static isCustomDomainCreated() {
    return DomainManager.didCustomDomainCreated;
  }

  static verifyCustomDomain() {
    return DomainManager.isCustomDomainVerified;
  }

  static domainCreated(name) {
    DomainManager.domainName = name;
    DomainManager.didDomainCreated = true;
  }

  static customDomainCreated(name) {
    DomainManager.customDomainName = name;
    DomainManager.didCustomDomainCreated = true;
  }

  static customDomainVerified(isVerified) {
    DomainManager.isCustomDomainVerified = isVerified;
  }

  static getDomainURL() {
    var str_link =
      "https://" + DomainManager.domainName + ApiConstants.SUB_DOMAIN + "/wb";
    return str_link;
  }

  static getCustomDomainName() {
    return DomainManager.customDomainName;
  }

  static getDomainName() {
    return DomainManager.domainName
  }
}
