export default class Template8 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#fff7f5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604896205638_hero-image%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Protect Yourself From Virus.",
        isImageHidden: false,
        text:
          "COVID-19 Be Aware Of Coronavirus\n\nLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer tookng.",
        paddingVertical: 2,
        paddingHorizontal: 2,
      },
      {
        id: 3,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604896503077_feature-01%402x.png",
            heading: "Health Security Mask Collection",
            text: "20% Off",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604896586829_feature-02%402x.png",
            heading: "Best Hand Sanitizer Collection",
            text: "20% Off",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604896630844_feature-03%402x.png",
            heading: "Medical Gloves & Sunglasses Collection",
            text: "20% Off",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 3,
        paddingHorizontal: 8,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Protect Your Health",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Our Featured Products",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604898855827_product-01%402x.png",
            heading: "Customized Reusable Face Mask",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604898962194_product-02%402x.png",
            heading: "Young Vision Hand Sanitizer",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899156781_product-03%402x.png",
            heading: "Customized Reusable Face Mask",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899185144_product-04%402x.png",
            heading: "Young Vision Hand Sanitizer",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899202869_product-05%402x.png",
            heading: "Customized Reusable Face Mask",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899214779_product-06%402x.png",
            heading: "Care Plus Medical Gloves",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "3",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 8,
      },
      {
        id: 9,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "center",
        textSize: "small",
        textColor: "#ffffff",
        buttonColor: "#ff6d33",
        imageShape: "square",
        text: "View All Products",
        paddingVertical: 4,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 10,
        type: "Image with text",
        backgroundColor: "#e8c4ac",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899227692_section-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Corona Health Protection Safety Kit.",
        isImageHidden: false,
        text:
          "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer tookng.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899892527_Screenshot%202020-11-09%20at%201.30.10%20PM.png",
            heading: "Giovanna Kling",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899910905_Screenshot%202020-11-09%20at%201.30.52%20PM.png",
            heading: "Kathryn Rohan",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604899941960_Screenshot%202020-11-09%20at%201.31.18%20PM.png",
            heading: "Eino Spencer",
            text:
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 4,
      },
      {
        id: 12,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
