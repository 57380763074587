export default class Template18 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604986821095_section-image-01%402x.png",
        imageAlignment: "left",
        imageShape: "square",
        heading: "MORNING BERRY",
        isImageHidden: false,
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque metus dui, porta a enim ut, commodo commodo nulla. Cras condimentum a felis ut posuere.",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Paragraph with title",
        backgroundColor: "#fdffd7",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Paragraph with title",
        backgroundColor: "#fdffd5",
        sectionHeight: null,
        textSize: "small",
        textColor: "#e23d6a",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "FATHERS DAY SPECIAL",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Featured collection",
        backgroundColor: "#fdffd7",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#2d2d2d",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987186083_featured-01%402x.png",
            heading: "Morning Berry",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987245232_featured-02%402x.png",
            heading: "Caramel Splash",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987481306_featured-03%402x.png",
            heading: "Dolphin Biscuit",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987544802_featured-04%402x.png",
            heading: "Pink Velvet",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987595284_featured-05%402x.png",
            heading: "White Ocean",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "5",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Paragraph with title",
        backgroundColor: "#fdffd5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#e23d6a",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "POPULAR CAKES",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989657226_popular-01%402x.png",
            heading: "Neon Cake Topper",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989706221_popular-02%402x.png",
            heading: "Blue Bubble",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989745824_popular-03%402x.png",
            heading: "Coco Flower",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989763549_popular-04%402x.png",
            heading: "Unicorn Valley",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989836168_popular-05%402x.png",
            heading: "Simba",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "5",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 9,
        type: "Image with text",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604990013850_section-image-02%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Section header",
        isImageHidden: false,
        text:
          "Pair large text with an image to give focus to your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
        paddingVertical: 4,
        paddingHorizontal: 8,
      },
      {
        id: 10,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "RECENT",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987186083_featured-01%402x.png",
            heading: "Flower Power",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987245232_featured-02%402x.png",
            heading: "Blue Bubble",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987544802_featured-04%402x.png",
            heading: "Coco Flower",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604987595284_featured-05%402x.png",
            heading: "Sparkly Pastel",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989657226_popular-01%402x.png",
            heading: "Sparkly Pastel",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "5",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 12,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 15,
        type: "Paragraph with title",
        backgroundColor: "#feffe9",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 13,
        type: "Paragraph with title",
        backgroundColor: "#feffe9",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "BYTES",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 14,
        type: "Featured collection",
        backgroundColor: "#feffe9",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989657226_popular-01%402x.png",
            heading: "Löffelbiskuit",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989706221_popular-02%402x.png",
            heading: "Unicorn Meringues",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989745824_popular-03%402x.png",
            heading: "Raspberry Mackerons",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989763549_popular-04%402x.png",
            heading: "Red Heaven",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604989836168_popular-05%402x.png",
            heading: "Vanilla Cupcakes",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: "5",
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 16,
        type: "Paragraph with title",
        backgroundColor: "#feffe9",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 17,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 18,
        type: "News Letter",
        backgroundColor: "#ff839c",
        heading: "Newsletter",
        text: "Please subscribe to get updates from our shop",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 19,
        type: "Paragraph with title",
        backgroundColor: "#f8f8f8",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
