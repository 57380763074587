import React, { Component } from "react";
import "../Common.css";
import "../App.css";
import CartManager from "./Managers/CartManager";
import PriceDetails from "./PriceDetails";
import DKTextField, { DKTextFieldType } from "./Components/DKTextField";
import ApiConstants from "../ApiConstants.js";
import CheckoutApiClient from "./API/CheckoutApiClient";
import DKLoading from "./Components/DKLoading";
import StoreManager from "./Managers/StoreManager";
import DKUtility from "../DKUtility";
import DKCountryUtility from "../DKCountryUtility";
import HeaderMenu from "../HeaderMenu";
import MenuManager from "../MenuManager";
import DKListPicker from "./Components/DKListPicker";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export default class CheckoutScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0,
      checkOutInfo: null,
      checkOutTapped: false,
      isLoading: false,
      needListPicker: false,
    };

    setTimeout(() => {
      this.resetData();
      this.setCheckoutInfo();
    }, 10);
  }

  setCheckoutInfo() {
    let cartProductData = CartManager.getProducts();
    cartProductData = cartProductData.map((obj) => {
      let product = {
        productId: obj.id,
        quantity: obj.quantity,
        addtime: "17",
      };
      return product;
    });

    this.setState({
      checkOutInfo: {
        tenantId: ApiConstants.TENANT_ID,
        product: cartProductData,
        memo: "",
        currency: StoreManager.getStoreCurrency(),
        buyerName: "",
        buyerEmail: "",
        address: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          countryCode: "",
        },
      },
    });
  }

  render() {
    return (
      <div
        className="column "
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        {/* <HeaderMenu
          menuSettings={MenuManager.getMenuSettings()}
          websiteTitle="Deskera"
          //   websiteLogo={WebsiteManager.getWebsiteLogo()}
          pages={[]}
          isReadOnly={false}
          isUserLoggedIn={this.state.isUserLoggedIn}
          onBackgroundColorChanged={this.backgroundColorChanged}
          onFullscreenTapped={this.fullscreenTapped}
          onLoginTapped={this.loginTapped}
          onSaveTapped={this.saveTapped}
          onPublishTapped={this.publishTapped}
          onNewPageTapped={this.newPageTapped}
          onPageButtonTapped={this.pageButtonTapped}
          onEditTapped={this.editWebsiteTapped}
        /> */}
        <div
          className="column parent-width"
          style={{
            padding: "2vw",
            paddingBottom: 20,
            maxWidth: 1200,
            alignSelf: "center",
            // flex: 1,
            // overflowY: "hidden",
          }}
        >
          <text className=" fw-b" style={{ fontSize: 22 }}>
            Payment info
          </text>
          <div
            className="column parent-width"
            style={{
              marginTop: 20,
              flex: 1,
              // overflowY: "hidden",
            }}
          >
            <div className="row parent-height">
              <div
                className="column parent-height"
                style={{
                  flex: 1,
                  // overflowY: "scroll",
                }}
              >
                {this.state.checkOutInfo && this.getBillingView()}
                <div className="column parent-width priceDetailsBottomPopup">
                  {this.getPriceDetails()}
                </div>
              </div>
              <div className="column parent-height border-radius-s  priceDetailsPopup">
                {this.getPriceDetails()}
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <DKLoading />}
      </div>
    );
  }

  resetData() {
    this.setState({
      totalAmount: CartManager.getTotalAmount(),
    });
  }
  isFormValid = () => {
    let isValid = true;
    if (this.state.checkOutInfo.buyerName.trim() === "") {
      isValid = false;
    }
    if (this.state.checkOutInfo.buyerEmail.trim() === "") {
      isValid = false;
    }
    if (!DKUtility.isValidEmail(this.state.checkOutInfo.buyerEmail)) {
      isValid = false;
    }
    if (this.state.checkOutInfo.address.address.trim() === "") {
      isValid = false;
    }
    if (this.state.checkOutInfo.address.city.trim() === "") {
      isValid = false;
    }
    if (this.state.checkOutInfo.address.state.trim() === "") {
      isValid = false;
    }
    if (this.state.checkOutInfo.address.country.trim() === "") {
      isValid = false;
    }
    if (this.state.checkOutInfo.address.zip.trim() === "") {
      isValid = false;
    }
    if (!isValid) {
      this.setState({
        checkOutTapped: true,
      });
    } else {
      this.setState({
        isLoading: true,
        checkOutTapped: true,
      });
    }
    return isValid;
  };
  checkOut = () => {
    if (this.isFormValid()) {
      this.setState({
        isLoading: true,
        checkOutTapped: true,
      });
      CheckoutApiClient.placeOrder(this, this.state.checkOutInfo);
    } else {
      this.setState({
        checkOutTapped: true,
      });
    }
  };

  ///////////////////////////////////
  ///////////////////////////////////
  orderPlacedSuccess() {
    this.setState({
      isLoading: false,
    });
    CartManager.emptyCart();
    this.resetData();
    // this.props.history.push("/order");
    this.props.onCheckout();
  }
  orderPlaceFailed() {
    this.setState({
      isLoading: false,
    });
  }
  ///////////////////////////////////
  ///////////////////////////////////

  getPriceDetails() {
    return (
      <PriceDetails
        totalAmount={this.state.totalAmount}
        buttonTitle={null}
        callback={this.checkOut}
      />
    );
  }
  getBillingView() {
    const stripePromise = loadStripe(ApiConstants.STRIPE_KEY, {
      stripeAccount: StoreManager.getStripeAccountID(),
    });

    return (
      <div className="column parent-width align-items-center ">
        <div
          className="column align-items-center border-s border-radius-s"
          style={{
            width: "95%",
            maxWidth: 600,
            padding: 20,
            boxSizing: "border-box",
          }}
        >
          <div
            className="column align-items-start"
            style={{ width: "90%", boxSizing: "border-box", fontSize: 14 }}
          >
            <text>Shipping and Billing information</text>

            <div
              className="border-box border-s border-radius-s shadow-s parent-width"
              style={{ padding: 30, marginTop: 15, marginBottom: 30 }}
            >
              <DKTextField
                placeHolder="Name"
                value={this.state.checkOutInfo.buyerName}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["buyerName"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.buyerName.trim() === ""
                }
              />
              <DKTextField
                placeHolder="Email"
                value={this.state.checkOutInfo.buyerEmail}
                isLowerCase={true}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["buyerEmail"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  (this.state.checkOutInfo.buyerEmail.trim() === "" ||
                    !DKUtility.isValidEmail(this.state.checkOutInfo.buyerEmail))
                }
              />
              <DKTextField
                placeHolder="Address"
                value={this.state.checkOutInfo.address.address}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["address"]["address"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.address.address.trim() === ""
                }
              />
              <DKTextField
                placeHolder="City"
                value={this.state.checkOutInfo.address.city}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["address"]["city"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.address.city.trim() === ""
                }
              />
              <DKTextField
                placeHolder="State"
                value={this.state.checkOutInfo.address.state}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["address"]["state"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.address.state.trim() === ""
                }
              />
              <DKTextField
                placeHolder="ZIP"
                value={this.state.checkOutInfo.address.zip}
                type={DKTextFieldType.NUMBER}
                onChange={(text) => {
                  let info = this.state.checkOutInfo;
                  info["address"]["zip"] = text;
                  this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.address.zip.trim() === ""
                }
              />
              <DKTextField
                placeHolder="Country"
                value={this.state.checkOutInfo.address.country}
                onChange={(text) => {
                  // let info = this.state.checkOutInfo;
                  // info["address"]["country"] = text;
                  // this.setState({ checkOutInfo: info });
                }}
                isInValid={
                  this.state.checkOutTapped &&
                  this.state.checkOutInfo.address.country.trim() === ""
                }
                isEditable={false}
                type={DKTextFieldType.STATIC}
                onClick={this.showListPicker}
              />
            </div>

            <text>Payment information</text>

            <div style={{ width: "100%", marginTop: 15 }}>
              <Elements stripe={stripePromise}>
                <StripeForm
                  totalAmount={this.state.totalAmount}
                  checkOutInfo={this.state.checkOutInfo}
                  checkIfFormValid={this.isFormValid}
                  onPaymentSuccess={this.paymentSuccess}
                  onPaymentFail={this.paymentFailed}
                />
              </Elements>
            </div>
            {this.state.needListPicker && this.getListPicker()}
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  getListPicker() {
    return (
      <div
        className="transparent-background"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      >
        <div
          className="popup-window"
          style={{ maxWidth: 250, maxHeight: 400, padding: 0 }}
        >
          <DKListPicker
            title="Select country"
            data={DKCountryUtility.getCountryData().map((obj) => obj.name)}
            onSelect={(index, value) => {
              let info = this.state.checkOutInfo;
              info["address"]["country"] = value;
              info["address"][
                "countryCode"
              ] = DKCountryUtility.getCountryCodeForIndex(index);
              this.setState({ checkOutInfo: info });
              this.hideListPicker();
            }}
            onClose={this.hideListPicker}
          />
        </div>
      </div>
    );
  }

  showListPicker = () => {
    this.setState({
      needListPicker: true,
    });
  };
  hideListPicker = () => {
    this.setState({
      needListPicker: false,
    });
  };

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  paymentSuccess = () => {
    CheckoutApiClient.placeOrder(this, this.state.checkOutInfo);
  };
  paymentFailed = () => {
    this.setState({
      isLoading: false,
    });
  };

  openCountryListPicker = () => {};
}
