export default class Template5 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "left",
        textSize: "large",
        textColor: "#000000",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654634680_wellness-hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "FRESH ORGANIC FROM THE FARM",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 2,
        type: "Blogs column",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654894755_wellness-product-1%402x.png",
            heading: "Fruits",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654946518_wellness-product-2%402x.png",
            heading: "Organic",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654963739_wellness-product-3%402x.png",
            heading: "Vegetables",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 4,
        paddingHorizontal: 10,
      },
      {
        id: 3,
        type: "Testimonials",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "large",
        textColor: "#000000",
        textAlignment: "left",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image: null,
            heading: "LOREM IPSUM DOLOR SIT AMET CONSECTETUR",
            text: "",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image: null,
            heading: "",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 2,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 4,
        type: "Text column with image",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654732425_wellness-collection-1%402x.png",
            heading: "Orange",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654761252_wellness-collection-2%402x.png",
            heading: "Mango",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654786833_wellness-collection-3%402x.png",
            heading: "Strawberry",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
          {
            id: 4,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604654865440_wellness-collection-4%402x.png",
            heading: "Banana",
            text: "Lorem ipsum dolor sit amet, sectetur adipiscing elit.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 4,
        paddingHorizontal: 8,
      },
      {
        id: 5,
        type: "Blogs column",
        backgroundColor: "#f5f5f5",
        heading: "DUIS AUTE IRURE DOLOR IN REPREHEN DERIT",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: true,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image: null,
            heading: "Excepteur sint occae",
            text:
              "Sed ut perspiciatis unde omnis te natus error sit voluptatem oreme ntium, totam rem aperiam aque ipsa quae ab illo inventore.",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image: null,
            heading: "Excepteur sint occae",
            text:
              "Sed ut perspiciatis unde omnis te natus error sit voluptatem oreme ntium, totam rem aperiam aque ipsa quae ab illo inventore.",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image: null,
            heading: "Excepteur sint occae",
            text:
              "Sed ut perspiciatis unde omnis te natus error sit voluptatem oreme ntium, totam rem aperiam aque ipsa quae ab illo inventore.",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 3,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: true,
        paddingVertical: 8,
        paddingHorizontal: 8,
      },
      {
        id: 6,
        type: "News Letter",
        backgroundColor: "#ffffff",
        heading: "Email",
        text: "Get latest product updates from us",
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#f5f5f5",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
