export default class Template17 {
  static getData() {
    return [
      {
        id: 1,
        type: "Image with text overlay",
        backgroundColor: "#c7c7c7",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "large",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982389313_hero-image%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "Winter Sale",
        text: "Up to 50% Off",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 4,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 3,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "small",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Sale Products",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 2,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 5,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982738058_product-image-01%402x.png",
            heading: "Embroidered Linen Shirt",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 6,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982760100_product-image-02%402x.png",
            heading: "Embroidered Linen Shirt",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982772847_product-image-03%402x.png",
            heading: "Embroidered Linen Shirt",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 8,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982784465_product-image-04%402x.png",
            heading: "Embroidered Linen Shirt",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 6,
      },
      {
        id: 5,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 6,
        type: "Image with text",
        backgroundColor: "#f5f5f5",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#222222",
        textAlignment: "left",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604982931751_section-image-01%402x.png",
        imageAlignment: "right",
        imageShape: "square",
        heading: "Deal of the Day 30% on Dresses",
        isImageHidden: false,
        text:
          "\n\nWrite in plain, conversational English; make sure you use the language that your target audience will understand. By necessity if you use industry terms, make sure to explain appropriately. For a short animated video, your script can be written using any word processor. Google docs and Ever note are also recommended. You may require script revision history and later your teammates also can use the comments feature.",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 7,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 8,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "Featured Products",
        isImageHidden: false,
        text: "",
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 9,
        type: "Featured collection",
        backgroundColor: "#ffffff",
        heading: "",
        text: "",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        imageShape: "square",
        isImageHidden: false,
        columns: [
          {
            id: 1,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604984632595_featured-01%402x.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 2,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604984654353_featured-02%402x.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 3,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604984668902_featured-03%402x.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
          {
            id: 7,
            type: "Column with image and text",
            image:
              "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604984880798_featured-04%402x.png",
            heading: "Your product name",
            text: "S$ 19.99",
            url: "",
          },
        ],
        numberOfColumnsPerRow: 4,
        isHorizontalColumn: false,
        isDistributeEqually: false,
        isSpaceEvenly: false,
        paddingVertical: 0,
        paddingHorizontal: 2,
      },
      {
        id: 10,
        type: "Paragraph with title",
        backgroundColor: "#ffffff",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#000000",
        textAlignment: "left",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
      {
        id: 11,
        type: "Image with text overlay",
        backgroundColor: "#ffffff",
        sectionHeight: "auto",
        textAlignment: "center",
        textSize: "medium",
        textColor: "#ffffff",
        image:
          "https://cdn-crm-staging-env.s3.amazonaws.com/staging/website-builder/114098/1604984943904_section-image-02%402x.png",
        imageAlignment: "middle",
        imageShape: "square",
        isImageHidden: false,
        heading: "THIS IS FASHIONSHOP",
        text: "\nYOUR FASHION AND STYLE DESTINATION   ",
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      {
        id: 12,
        type: "Button",
        backgroundColor: "#ffffff",
        textAlignment: "center",
        textSize: "small",
        textColor: "#000000",
        buttonColor: "#ffff00",
        imageShape: "square",
        text: "SHOP FOR WOMEN",
        paddingVertical: 2,
        paddingHorizontal: 6,
        webURL: "",
      },
      {
        id: 13,
        type: "News Letter",
        backgroundColor: "#f4f4f4",
        heading: "SUBSCRIBE TO NEWSLETTER",
        text: "Get updated on new product releases and promotions.",
        textSize: "small",
        textColor: "#000000",
        textAlignment: "center",
        paddingVertical: 4,
        paddingHorizontal: 6,
      },
      {
        id: 14,
        type: "Paragraph with title",
        backgroundColor: "#585858",
        sectionHeight: null,
        textSize: "medium",
        textColor: "#ffffff",
        textAlignment: "center",
        image: null,
        imageAlignment: "left",
        imageShape: "square",
        heading: "",
        isImageHidden: false,
        text: "Designed by Deskera Website Builder",
        paddingVertical: 2,
        paddingHorizontal: 6,
      },
    ];
  }
}
